import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import logoWhite from "../images/enimeris-logo-website-white.png";
import img404 from "../images/404.png";

import { Helmet } from "react-helmet"

import "./../components/ErrorPage.css";


function NotFoundPage() {
    const [languageGreek, setLanguageGreek] = useState(false)

    useEffect(() => {
        var userLang = navigator.language || navigator.userLanguage;
        if (userLang.toLowerCase().includes("el")) {
            setLanguageGreek(true)
        } else {
            setLanguageGreek(false)
        }
    }, [])
    return (
        <div>
            <Helmet>
                <title>404 | Not Found</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div style={{ backgroundColor: "#143b68" }}>
                <Link to={"/"} className="py-6 block">
                    <img className="w-56 md:w-64 m-auto" src={logoWhite} alt="white-logo" />
                </Link>
            </div>
            <div className="error-page-container">
                <div className="flex flex-wrap justify-center">
                    <img className="w-56 md:w-64 m-auto" src={img404} alt="404" />
                    { !languageGreek
                        ?
                        <React.Fragment>
                            <h1 className="py-8 text-xl w-full text-center" style={{ color: "#77838F" }}>OOPPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.</h1>
                            <Link to={"/en"} className="cf-bttn" style={{ backgroundColor: "#143b68", marginTop: 0 }}>
                                Return to Homepage
                            </Link>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h1 className="py-8 text-xl w-full text-center" style={{ color: "#77838F" }}>ΩΧΧΧ! Η ΣΕΛΙΔΑ ΠΟΥ ΠΡΟΣΠΑΘΕIΤE ΝΑ ΔΕΙΤΕ ΔΕΝ ΜΠΟΡΕΙ ΝΑ ΒΡΕΘΕΙ.</h1>
                            <Link to={"/"} className="cf-bttn" style={{ backgroundColor: "#143b68", marginTop: 0 }}>
                                Επιστροφή στην Αρχική
                            </Link>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}


export default NotFoundPage
